/**
 * Timetastic
 * Footer component
 */
import React from "react"

// Helpers
import { trackCta } from "src/helpers"

// Styles
import "./caseStudies.scss"

// JSX
const CaseStudies = () => (
  <div className="c-case-studies">
    <div className="c-case-study is-unanimated">
      <img
        loading="lazy"
        alt="Sookio"
        className="c-case-study__image"
        height="400"
        src="/images/customers/sookio.jpg"
        width="540"
      />
      <p>
        Sookio had a spring clean of their internal documents and tools, and Sue
        decided the dreaded spreadsheet would be banished to the shadows once
        and for all.
      </p>
      <p>
        <a
          aria-label="Read Sookio case study"
          className="c-case-study__more"
          href="https://timetastic.co.uk/blog/case-study-sookio/"
          onClick={() => {
            trackCta("AboutCaseStudySookio")
          }}
        >
          Read case study{" "}
          <svg
            aria-hidden="true"
            className="c-more-icon"
            focussable="false"
            height="16"
            viewBox="0 0 20 16"
            width="20"
          >
            <use xlinkHref="#svg-arrow" />
          </svg>
        </a>
      </p>
    </div>
    <div className="c-case-study is-unanimated">
      <img
        loading="lazy"
        alt="Format"
        className="c-case-study__image"
        height="400"
        src="/images/customers/format.jpg"
        width="540"
      />
      <p>
        Great designs, rested minds — that’s the Format way. Here’s how
        Timetastic keeps things simple for a close-knit team who know the value
        of downtime.
      </p>
      <p>
        <a
          aria-label="Read Format case study"
          className="c-case-study__more"
          href="https://timetastic.co.uk/blog/format/"
          onClick={() => {
            trackCta("AboutCaseStudyFormat")
          }}
        >
          Read case study{" "}
          <svg
            aria-hidden="true"
            className="c-more-icon"
            focussable="false"
            height="16"
            viewBox="0 0 20 16"
            width="20"
          >
            <use xlinkHref="#svg-arrow" />
          </svg>
        </a>
      </p>
    </div>
  </div>
)

export default CaseStudies
