/**
 * Timetastic
 * Free Trial component
 */
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { doSignupClick } from "../../helpers/analytics"

// Helpers
import { trackCta } from "src/helpers"

// Styles
import "./freeTrial.scss"

// JSX
const FreeTrial = ({
  coloured,
  colouredWhiteBottom,
  body,
  buttonCopy,
  buttonHref,
  gradientBottom,
  noLink,
  title,
  trackCtaLabel,
  imgClass,
}) => {
  const isInternalHref = buttonHref.startsWith("/")
  const buttonClass = `c-button c-button--xl ${
    coloured ? "c-button--primary-inverse" : "c-button--primary"
  }`

  return (
    <section
      className={`is-unanimated c-section u-text-center c-free-trial${
        coloured ? " c-free-trial--coloured" : ""
      }${colouredWhiteBottom ? " c-free-trial--coloured-alt" : ""}${
        gradientBottom ? " c-free-trial--gradient-bottom" : ""
      }`}
    >
      <div className="u-inner">
        <h2 className="h h2 c-free-trial__title u-text-left-mobile">{title}</h2>
        <div className="u-inner c-free-trial__copy u-text-left-mobile">
          <p>{body}</p>
        </div>
        <div className={imgClass}></div>
        {/* Check to see if its an external (or internal) link and use `<Link>` accordingly */}
        {isInternalHref && (
          <Link
            className={buttonClass}
            to={buttonHref}
            onClick={() => {
              trackCta(trackCtaLabel)
            }}
          >
            {buttonCopy}
          </Link>
        )}
        {!isInternalHref && (
          <a
            className={buttonClass}
            href={buttonHref}
            onClick={() => {
              trackCta(trackCtaLabel)
              doSignupClick()
            }}
          >
            {buttonCopy}
          </a>
        )}
        {noLink && (
          <div className="c-free-trial__no-link">
            Free for one month - no card required
          </div>
        )}

        {!noLink && (
          <a
            className="u-em-link c-demo-link"
            href="https://calendly.com/sarah-timetastic/timetastic-demo"
            target="blank"
            rel="nofollow noreferrer noopener"
            onClick={() => {
              trackCta("BookDemo")
            }}
          >
            <span>Book a demo</span>
          </a>
        )}
      </div>
    </section>
  )
}

FreeTrial.propTypes = {
  coloured: PropTypes.bool,
  colouredWhiteBottom: PropTypes.bool,
  body: PropTypes.string.isRequired,
  buttonCopy: PropTypes.string,
  buttonHref: PropTypes.string,
  gradientBottom: PropTypes.bool,
  noLink: PropTypes.bool,
  title: PropTypes.string,
  trackCtaLabel: PropTypes.string,
  imgClass: PropTypes.string,
}

FreeTrial.defaultProps = {
  coloured: false,
  colouredWhiteBottom: false,
  buttonCopy: "Start free trial",
  buttonHref: "/pricing/",
  gradientBottom: false,
  noLink: true,
  title: "Get one month free!",
  trackCtaLabel: "SignUp",
}

export default FreeTrial
